import React, { Component } from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

import Header from './Pages/Components/Header.js';
import Sidebar from './Pages/Components/Sidebar.js';
import Footer from './Pages/Components/Footer.js';
import ClosingList from "./Pages/ClosingList.js";
import Home from './Pages/Home.js';
import Article from './Pages/Article.js';
import Contest from './Pages/Contest.js';
import Gas from './Pages/Gas.js';
import PlayOfTheWeek from './Pages/PlayOfTheWeek.js';
import Weather from './Pages/Weather.js';
import SatRadar from './Pages/SatRadar.js';
import Pollen from './Pages/Pollen.js';
import Text from './Pages/Text.js';
import BusinessRewards from "./Pages/BusinessRewards.js";
import SnowTrailsTicket from "./Pages/SnowTrailsTicket.js";
// import HaringRealtyHomeShow from "./Pages/HaringRealtyHomeShow.js";
// import ILoveMyJob from "./Pages/ILoveMyJob.js";
// import InsideMansfieldCitySchools from "./Pages/InsideMansfieldCitySchools.js";
import FocusOnNorthCentralOhio from "./Pages/FocusOnNorthCentralOhio.js";
// import MarchOnHalftimeShows from "./Pages/MarchOnHalftimeShows.js";
import SittinDownWithKelbyKing from "./Pages/SittinDownWithKelbyKing.js";
// import SeniorLiving from "./Pages/SeniorLiving.js";
import CoffeeTalk from "./Pages/CoffeeTalk.js";
import StJohn from "./Pages/StJohn.js";
import ChurchServices from "./Pages/ChurchServices.js";
import EmergencyLevels from "./Pages/EmergencyLevels.js";
import SparkingTheConversation from "./Pages/SparkingTheConversation.js";

import ContactUs from "./Pages/ContactUs.js";
import Advertise from "./Pages/Advertise.js";
import ContestRules from "./Pages/ContestRules.js";
import PublicFileWRGM from "./Pages/PublicFileWRGM.js";
import PublicFileWVNO from "./Pages/PublicFileWVNO.js";
import PublicFileWOHZ from "./Pages/PublicFileWOHZ.js";
import PublicFileWMFD from "./Pages/PublicFileWMFD.js";
import EEO from "./Pages/EEO.js";
import ClosedCaptioning from "./Pages/ClosedCaptioning.js";
import LiveTVComponent from "./Pages/Components/LiveTVComponent";
import Scores from "./Pages/Scores";
import ViewElection from "./Pages/ViewElection";
import SmallBusinessForm from "./Pages/SmallBusinessForm";
import RestaurantDirectory from "./Pages/RestaurantDirectory";
import LocalSportsTVBanner from "./Pages/Components/LocalSportsTVBanner";


import "semantic-ui-css/semantic.min.css"
import './App.css';


export default class App extends Component {

  render() {

    return (
      <Router>
        <Switch>
          <Route path="/weather/:satrad" component={SatRadar} />
          <Route path="/" render={ () => {
            return(
              <div>

                <Header />

                <div className="main-content">
                  <div className="cp-category-page2">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-8">

                          <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/news" component={Article} />
                            <Route exact path="/article/:title/:id" component={Article} />
                            <Route exact path="/contest/:id" component={Contest} />
                            <Route exact path="/entertainment" component={Article} />
                            <Route exact path="/events" component={Article} />
                            <Route exact path="/obituaries" component={Article} />
                            <Route exact path="/gas" component={Gas} />
                            <Route exact path="/sports" component={Article} />
                            <Route exact path="/playoftheweek" component={PlayOfTheWeek} />
                            <Route exact path="/weather" component={Weather} />
                            <Route exact path="/pollen" component={Pollen} />
                            <Route exact path="/text" component={Text} />
                            <Route exact path="/closings" component={ClosingList}/>
                            <Route exact path="/closings/:closingId" component={ClosingList}/>
                            <Route exact path="/business-contest" component={BusinessRewards}/>
                            <Route exact path="/snow-trails-ticket" component={SnowTrailsTicket}/>
                            {/*<Route exact path="/haring-realty-home-show" component={HaringRealtyHomeShow}/>*/}
                            {/*<Route exact path="/i-love-my-job" component={ILoveMyJob}/>*/}
                            <Route exact path="/contact-us" component={ContactUs}/>
                            <Route exact path="/advertise" component={Advertise}/>
                            <Route exact path="/contest-rules" component={ContestRules}/>
                            <Route exact path="/publicfile-wrgm" component={PublicFileWRGM}/>
                            <Route exact path="/publicfile-wvno" component={PublicFileWVNO}/>
                            {/*<Route exact path="/publicfile-wohz" component={PublicFileWOHZ}/>*/}
                            <Route exact path="/publicfile-wmfd" component={PublicFileWMFD}/>
                            <Route exact path="/eeo" component={EEO}/>
                            <Route exact path="/closed-captioning" component={ClosedCaptioning}/>
                            {/*<Route exact path="/inside-mansfield-city-schools" component={InsideMansfieldCitySchools}/>*/}
                            <Route exact path="/focus-on-north-central-ohio" component={FocusOnNorthCentralOhio}/>
                            {/*<Route exact path="/march-on-halftime-shows" component={MarchOnHalftimeShows}/>*/}
                            <Route exact path="/sitting-down-with-kelby-king" component={SittinDownWithKelbyKing}/>
                            {/*<Route exact path="/senior-living" component={SeniorLiving}/>*/}
                            <Route exact path="/st-john" component={StJohn}/>
                            {/*<Route exact path="/church-services" component={ChurchServices}/>*/}
                            <Route exact path="/coffee-talk" component={CoffeeTalk}/>
                            <Route exact path="/sparking-the-conversation" component={SparkingTheConversation}/>
                            <Route exact path="/emergency-levels" component={EmergencyLevels}/>
                            {/*<Route exact path="/live" component={LiveTVComponent} />*/}
                            <Route exact path='/scores' component={Scores}/>
                            <Route exact path='/election/:type/:countyId?' component={ViewElection} />

                            <Route exact path='/restaurants' component={RestaurantDirectory}/>
                            <Route exact path='/small-business-form' component={SmallBusinessForm}/>

                            <Route>
                              <Redirect push to="/" />
                            </Route>
                          </Switch>


                        </div>
                        <div className="col-md-4">

                          <Route component={Sidebar} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Route component={Footer} />

              </div>
            )
          }}/>
        </Switch>
      </Router>

    )
  }
}
